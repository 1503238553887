var $windowWidth;

(function ($) {
  $(document).ready(function(){
    // // Home page second visit logic
    // if ($('body').hasClass('home')) {
    //   if (!localStorage.getItem('second_visit')) {
    //     localStorage.setItem('second_visit', true);
    //   } else {
    //     $('body').addClass('second_visit');
    //   }
    // }

    // Search icon click
    $(document).on('click', '.search-icon', function() {
      $('.form-inline').slideToggle("fast");
      $('.search-block').toggleClass('active');
    });

    // Nav-toggle
    $('.nav-toggle').click(function(){
      $('.hamburger').toggleClass('is-active');
    });

    // Nav-collapse
    $(".nav-collapse .menu > li.sub > a").each(function() {
      $(this).removeAttr("href");
    });

    // Menu - Open/Close Menu
    $(document).on('click', '.nav-collapse .menu > li > a', function() {
      $(this).parent().find('ul').toggleClass('open-submenu');
      $(this).parent().siblings().children().next().removeClass("open-submenu");
      $('.nav-collapse .menu > li').removeClass("active");
      $(this).parent().toggleClass('active');
      //return false;
    });

    // SubMenu - Open/Close Menu
    $(document).on('click', '.nav-toggle', function() {
      $('.menu_overlay').slideToggle("fast");
      $('.header').toggleClass('open-menu');
    });

    // adjust bootstrap menu click
    $(document).on('click', '.dropdown-item', function() {
      $(this).parent().addClass('show');
    });


    // circles
    $(document).on('click', '.pulsating-circle', function() {
      $('.pulsating-circle').removeClass('active');
      $(this).toggleClass('active');
    });
    $(document).on('click', '.pulsating-circle.active', function() {
      $(this).removeClass('active');
    });

    // Button - Scroll-Up
    $(document).on('click', '.js-scroll-up', function() {
      $('html, body').animate({
          scrollTop: 0
      }, 1000);
    });

    // Function - Window Scroll
    $(window).scroll(function() {

      // Function - Scroll-Up Button
      scrollUpButton(); // DON'T REMOVE

    });


    // Function - Scroll-Up Button
    function scrollUpButton() {

      // Variables
      var windowHeight = $(window).height(); // Set windowHeight
      var scrollHeight = $(document).scrollTop(); // Set scrollHeight

      // Show/Hide Scroll-Up Button
      if(scrollHeight >= windowHeight) {
          $('.js-scroll-up').css('bottom', 25);
      } else {
          $('.js-scroll-up').css('bottom', -65);
      }

    }




    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],

      dots: false,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });

  });
})(jQuery);
